export const API_URL_RESEND_EMAIL = '/api/v2/admins/auths/register/resend'
export const API_URL_VERIFY_REGISTER = 'api/v2/admins/auths/register/verify'
export const API_URL_LOGIN = '/api/v2/admins/auths/login'
export const API_URL_FETCH_ME = '/api/v2/auths/me'
export const API_URL_RESET_PASSWORD = 'api/v2/admins/auths/forgot-password'

export const API_URL_FORGOT_PW_SEND_EMAIL = 'api/v2/admins/auths/forgot-password/send'
export const API_URL_FORGOT_PW_VERIFY_TOKEN = 'api/v2/admins/auths/forgot-password/verify'

export const API_URL_UPDATE_PASSWORD = '/api/v2/admins/accounts/reset-password'

export const API_URL_SKILLS = '/api/v2/public/skills'
export const API_URL_JOB_CATEGORIES = '/api/v2/public/job_categories'
export const API_URL_LIST_JOB = 'api/v2/companies/jobs'
export const API_URL_GET_JOB_DETAIL = '/api/v2/companies/jobs'
export const API_URL_GET_JOB_CONSULTANTS_DETAIL = '/api/v2/consultants/jobs'
export const API_URL_GET_PROFILE_COMPANY = '/api/v2/companies/current_company'

export const API_URL_LIST_TEAM_MEMBER = '/api/v2/companies/members'
export const API_URL_INVITE_TEAM_MEMBER = '/api/v2/companies/members/invites'
export const API_URL_RESEND_INVITE_MEMBER_EMAIL = '/api/v2/companies/members/invites/resend'
export const API_URL_VERIFY_MEMBER_INVITE = '/api/v2/companies/members/invites/verify'
export const API_URL_UPDATE_MEMBER_LOGIN = '/api/v2/companies/members/invites/update'
export const API_URL_CANDIDATES_JOB = '/api/v2/candidates/jobs'
export const API_URL_CANDIDATES_LOGIN_SOCIAL = 'api/v2/auths/login/social'
export const API_URL_CLIENT_INFO_FROM_TOKEN = (token: string): string => `/api/v2/consultants/clients/info?token=${token}`
export const API_URL_CLIENT_ACTIVE = `/api/v2/consultants/clients/active`

export const API_FETCH_LOCATION = '/api/v2/public/locations'
export const API_CANDICATE_PROFILE = '/api/v2/candidates/profile'
export const API_URL_APPLICANTS = '/api/v2/companies/applicants'

export const API_URL_COMPANY_CREATE_CLIENT = 'api/v2/consultants/clients'
export const API_URL_LIST_ACCOUNT_MANAGERS = 'api/v2/consultants/account_managers'

export const API_URL_CONSULTANT_CREATE_JOB_4_CLIENT = `/api/v2/consultants/jobs`

export const API_URL_CONSULTANT_GET_JOBS = `/api/v2/consultants/jobs`
export const API_URL_CONSULTANT_GET_ACTIVE_JOBS = `/api/v2/consultants/jobs/actives`
export const API_URL_LOCATION = '/api/v2/public/locations'
export const API_URL_COMPANIES_MEMBERS = '/api/v2/companies/members?fetch_id=true'
export const API_URL_CONSULTANT_CLIENTS = '/api/v2/consultants/clients?fetch_id=true'
export const API_URL_CONSULTANT_GET_JOB_ORDERS = `/api/v2/consultants/job_orders`

export const API_URL_CONSULTANT_CLIENT = `/api/v2/consultants/clients?status=1`
export const API_URL_JOBS_CANDIDATES = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/candidates`
}

export const API_URL_CONSULTANT_CREATE_PROFILE = `/api/v2/consultants/candidates/profile`

export const API_URL_COMPANY_JOB_STAGE = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/stages`
}
export const API_URL_COMPANY_APPLICANTS_STAGE = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/applicants/${jobId}/stages/update`
}

export const API_URL_CONSULTANT_COMPANY_BLOCK = (companyId: number): string => `/api/v2/consultants/companies/${companyId}/block`
export const API_URL_CONSULTANT_APPLICANTS_DISQUALIFY = (candidateId: number | string): string =>
  `/api/v2/consultants/applicants/${candidateId}/disqualify`

export const API_URL_COMPANY_CANDIDATE_EVALUATION = (applicantId: string): string => `/api/v2/companies/profiles/${applicantId}/evaluations`
export const API_URL_COMPANY_CANDIDATE_EVALUATION_GET = (applicantId: string | number, id: string): string =>
  `/api/v2/companies/profiles/${applicantId}/evaluations/${id}`
export const API_URL_JOB_NOTES = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/notes`
}
export const API_URL_UPDATE_JOB_NOTES = (jobId: number | string | string[], id: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/notes/${id}`
}
export const API_URL_APPLICANTS_NOTES = (profile_id: number | string | string[]): string => {
  return `/api/v2/companies/profiles/${profile_id}/notes`
}
export const API_URL_UPDATE_APPLICANTS_NOTES = (profile_id: number | string | string[], id: number | string | string[]): string => {
  return `/api/v2/companies/profiles/${profile_id}/notes/${id}`
}
export const API_URL_TALENT_POOL = `/api/v2/public/talent_pools`
export const API_URL_REMOVE_MEMBER = (id_member: string | string[] | number) => `/api/v2/admins/members/${id_member}/active`
export const API_URL_CHECK_VALIDATION_EMAIL = `/api/v2/consultants/candidates/check_email`
export const API_URL_CLIENT_NOTES = (clientId: number | string | string[]): string => {
  return `/api/v2/consultants/clients/${clientId}/notes`
}
export const API_URL_UPDATE_CLIENT_NOTES = (clientId: number | string | string[], noted_id: number | string | string[]): string => {
  return `/api/v2/consultants/clients/${clientId}/notes/${noted_id}`
}

export const API_URL_EMAILTEMPLATE_DETAIL = (id: string | string[] | number) => `/api/v2/companies/email_templates/${id}`

export const API_URL_CONSULTANT_PLACEMENT = (client_id: string | number | string[]) => `/api/v2/consultants/clients/${client_id}/placements`

export const API_CONSULTANT_COMPANY_DETAIL = (company_id: string | number | string[]) => `/api/v2/consultants/companies/${company_id}`
export const API_CONSULTANT_COMPANIES_JOB_POST = (id: string | string[] | number) => `/api/v2/consultants/companies/${id}/job_posts`
export const API_CONSULTANT_UPDATE_COMPANY = (company_id: string | string[] | number) =>
  `/api/v2/consultants/companies/${company_id}/update`

export const API_ADMIN_COMPANY_LIST = '/api/v2/admins/companies'
export const API_URL_ADMIN_GET_JOBS = `/api/v2/admins/jobs`
export const API_URL_ADMIN_CREATE_JOB = `/api/v2/admins/jobs`
export const API_CONSULTANT_CONTACT_REQUEST = (company_id: string | string[] | number) =>
  `/api/v2/consultants/companies/${company_id}/contact_requests`

export const API_COMPANIES_RECRUITERS = `/api/v2/companies/recruiters`
export const API_COMPANIES_RECRUITERS_ASSIGN_JOB = (jobID: number | string): string =>
  `/api/v2/companies/jobs/${jobID}/recruiters/assign_job`
export const API_COMPANIES_RECRUITERS_REMOVE_ASSIGN_JOB = (jobID: number | string, id: number | string): string =>
  `/api/v2/companies/jobs/${jobID}/recruiters/${id}/remove_assigned_job`

export const API_COMPANIES_PROFILE = `/api/v2/companies/profiles`
export const API_ADMIN_MEMBERS = `/api/v2/admins/members`
export const API_ADMIN_INVITES = `/api/v2/admins/members/invites`
export const API_ADMIN_INVITES_RESEND = `${API_ADMIN_INVITES}/resend`
export const API_ADMIN_INVITES_VERIFY = `${API_ADMIN_INVITES}/verify`
export const API_ADMIN_INVITES_UPDATE = `${API_ADMIN_INVITES}/update`

export const API_COMPANY_BRANCH_ADDRESSES = 'api/v2/companies/company_branch_addresses'
